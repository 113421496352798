import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../static/images/logo.svg"

const Header = ({siteTitle}) => (
    <header className="header">
        <div className="container">
            <div className="top-menu row">
                <div className="logo col-xs-12 center-xs col-sm-4 start-sm">
                    <Link to="/" title="Home Office Experts">
                        <img alt="Logo" src={logo}/>
                    </Link>
                </div>
                <nav className="col-xs-12 around-xs col-sm-8 end-sm">
                    <Link activeClassName="active" to="/three-steps">3 Schritte</Link>
                    <Link activeClassName="active" to="/promise">Unser Versprechen</Link>
                    <Link className="mailButton" to="/contact">Jetzt loslegen</Link>
                </nav>
            </div>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
