/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql, Link} from "gatsby"

import Header from "./header"
import "../../static/styles/main.scss"
import logo from "../../static/images/logo.svg"

const Layout = ({children}) => (
    <StaticQuery query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `} render={data => (
        <>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <main>{children}</main>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="widget__item">
                                <div className="logo">
                                    <Link to="/" title="freshcells">
                                        <img alt="Logo" src={logo}/>
                                    </Link>
                                </div>

                                <div className="about">
                                    <p>Es ist nicht nur ein Gebot des wirtschaftlichen Überlebens, Ihr Unternehmen handlungsfähig zu erhalten. Es ist auch wichtig für Ihre Beschäftigten, jetzt aktiv am Überleben des Unternehmens und damit ihres eigenen Arbeitsplatzes aktiv mitzuwirken.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 col-sm-offset-1">
                            <div className="widget__item">
                                <div className="links">
                                    <h4>Leistungen</h4>
                                    <ul>
                                        <li><Link to="/three-steps" title="Contact Us">3 Schritte</Link></li>
                                        <li><Link to="/promise" title="Privacy Policy">Unser Versprechen</Link></li>
                                        <li><Link to="/contact" title="Terms Of Use">Kontakt</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3">
                            <div className="widget__item">
                                <div className="links">
                                    <h4>Support</h4>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.freshcells.de/de/privacy-policy/"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                    Datenschutz
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.freshcells.de/de/imprint/"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                    Impressum
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright">
                        <p>Copyright {new Date().getFullYear()}, {` `} <a href="https://freshcells.de" rel="noopener noreferrer"  title="freshcells GmbH">freshcells GmbH</a>.</p>
                    </div>
                </div>
            </footer>
        </>
    )}/>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
